import Vue from "vue"; //引入Vue
import Router from "vue-router"; //引入vue-router
Vue.use(Router); //Vue全局使用Router
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/404",
      component: () => import("@/views/404"),
      hidden: true,
    },
    {
      path: "/",
      redirect: "home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home/index"),
    },
    {
      path: "/buddha",
      name: "buddha",
      component: () => import("@/views/home/buddha"),
    },
    {
      path: "/futian",
      name: "futian",
      component: () => import("@/views/home/futian"),
    },
    {
      path: "/palace",
      name: "palace",
      component: () => import("@/views/home/palace"),
    },
    {
      path: "/courtesy",
      name: "courtesy",
      component: () => import("@/views/form/courtesy"),
    },
    {
      path: "/pujaRegistration",
      name: "pujaRegistration",
      component: () => import("@/views/form/pujaRegistration"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }; // x控制左右，y控制上下
  },
});
